import React from "react"
import { Link, useStaticQuery } from "gatsby"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Projects = () => {
  const { allMarkdownRemark: { edges: posts } } = useStaticQuery(graphql`{
    allMarkdownRemark(
      filter: { frontmatter: { published: { eq: true }, section: { eq: "projects" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
        }
      }
    }
  }`);

  console.log(posts);

  return (
    <Layout>
      <SEO title="Home" />
      {posts.map(({ node: { id, frontmatter: { path, title } } }) => (
        <div
          key={id}
          style={{
            margin: '10px 0px',
          }}
        >
          <Link to={path}>{title}</Link>
        </div>
      ))}
    </Layout>
  );
};

export default Projects;
